import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import {
	Box,
	FormControlLabel,
	Switch,
	SwitchProps,
	Typography,
	styled,
} from '@mui/material';
import { ReactNode } from 'react';

interface AppSwitchProps {
	label: string;
	infoPopUp?: ReactNode;
	required?: boolean;
	hidden?: boolean;
	// onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function AppSwitch<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	infoPopUp,
	required,
	...props
}: AppSwitchProps & UseControllerProps<TFieldValues, TName> & SwitchProps) {
	const { field } = useController(props);

	return (
		<Box display='flex' justifyContent='space-between' alignItems='center'>
			<Typography variant='body2' color='common.neutral40'>
				{label}
			</Typography>
			<Switch
				disableRipple
				onChange={field.onChange}
				checked={field.value}
				aria-label={label}
				color='success'
			/>
		</Box>
	);
}
