import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Switch,
	Typography,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AppDropDownMenu from '@/common/components/form-inputs/AppDropDownMenu';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import {
	useGetFriendsDefaultPermissionsQuery,
	useSetFriendsDefaultPermissionsMutation,
} from '@/api/friend/friendApiSlice';
import { PermissionOptions } from '@/api/enum/relatives.enum';
import { FriendPermissionDto } from '@/api/DTO/friend/friend.interface';
import AppSwitch from '@/common/components/form-inputs/AppSwitch';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
	page?: string;
}

const FriendPowerSettingModal = (props: Props) => {
	const { page } = props;
	const { t } = useTranslation();
	const { userId } = useParams();
	const [setFriendsDefaultPermissions] =
		useSetFriendsDefaultPermissionsMutation();

	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const { data: defaultPermissions } = useGetFriendsDefaultPermissionsQuery(
		userId || '',
		{
			refetchOnMountOrArgChange: true,
			skip: !userId,
		}
	);

	const defaultValues: DefaultValues<FriendPermissionDto> = {};

	const methods = useForm<FriendPermissionDto>({
		defaultValues,
	});

	useEffect(() => {
		methods.setValue('searchable', defaultPermissions?.data.searchable);
		methods.setValue('requestable', defaultPermissions?.data.requestable);
		methods.setValue('stepViewEnum', defaultPermissions?.data.stepViewEnum);
		methods.setValue('mapViewEnum', defaultPermissions?.data.mapViewEnum);
		methods.setValue('mascotViewEnum', defaultPermissions?.data.mascotViewEnum);
		methods.setValue('avatarViewEnum', defaultPermissions?.data.avatarViewEnum);
	}, [defaultPermissions]);

	const onSubmit: SubmitHandler<FriendPermissionDto> = async (data) => {
		const response = await setFriendsDefaultPermissions({
			...data,
			userId: userId || '',
		});
		if ('data' in response) {
			enqueueSnackbar(t('snackbar.friendPowerSetSuccess'), {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
			handleClose();
		}
	};

	return (
		<>
			{page == 'detail' ? (
				<span
					onClick={() => {
						setOpen(true);
					}}
					style={{ color: '#2B7DB2' }}
				>
					{t('common.setting')}
				</span>
			) : (
				<Button
					variant='text'
					sx={{ gap: '5px' }}
					onClick={() => {
						setOpen(true);
					}}
				>
					<VisibilityOutlinedIcon />
					{t('relative.setFriendPower')}
				</Button>
			)}

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='sm'
				fullWidth
			>
				<DialogTitle variant='h5'>{t('relative.setFriendPower')}</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent
				// sx={{
				// 	minHeight: '765px',
				// }}
				>
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<Grid
								container
								alignItems='center'
								sx={{ height: '100%' }}
								columnSpacing={3}
								rowSpacing={2}
							>
								<Grid item xs={12}>
									<Typography variant='body1'>
										{t('relative.canSetPower')}：
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Box
										display='flex'
										justifyContent='space-between'
										alignItems='center'
									>
										<Typography variant='body2' color='common.neutral40'>
											{t('relative.canSearchMe')}
										</Typography>
										<AppSwitch
											color='success'
											control={methods.control}
											name='searchable'
											inputProps={{ 'aria-label': 'controlled' }}
											label={''}
										/>
									</Box>
									<Box
										display='flex'
										justifyContent='space-between'
										alignItems='center'
									>
										<Typography variant='body2' color='common.neutral40'>
											{t('relative.canAddMe')}
										</Typography>
										<AppSwitch
											color='success'
											control={methods.control}
											name='requestable'
											inputProps={{ 'aria-label': 'controlled' }}
											label={''}
										/>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Typography variant='body1'>
										{t('relative.canSeeContent')}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<AppDropDownMenu
										control={methods.control}
										name='stepViewEnum'
										label={t('relative.stepViewEnum')}
										options={PermissionOptions}
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<AppDropDownMenu
										control={methods.control}
										name='mapViewEnum'
										label={t('relative.mapViewEnum')}
										options={PermissionOptions}
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<AppDropDownMenu
										control={methods.control}
										name='mascotViewEnum'
										label={t('relative.mascotViewEnum')}
										options={PermissionOptions}
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<AppDropDownMenu
										control={methods.control}
										name='avatarViewEnum'
										label={t('relative.avatarViewEnum')}
										options={PermissionOptions}
										required
									/>
								</Grid>
							</Grid>
						</form>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Button
						type='submit'
						variant='contained'
						onClick={methods.handleSubmit(onSubmit)}
					>
						{t('button.confirm')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default FriendPowerSettingModal;
