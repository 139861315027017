import {
	Box,
	FormControl,
	FormHelperText,
	TextField,
	TextFieldProps,
	Typography,
} from '@mui/material';
import {
	FieldPath,
	FieldValues,
	UseControllerProps,
	useController,
} from 'react-hook-form';

export enum InputType {
	normal,
	number,
	amount, // amount of money
	english,
	lastName,
}

export interface AppNumberTextFieldProps {
	inputType?: InputType;
	maxLength?: number;
	nonNegative?: boolean;
	showMaxLength?: boolean;
	fieldLabel?: string;
	onChange?: () => void;
}

export default function qAppNumberTextField<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	inputType,
	maxLength,
	nonNegative,
	showMaxLength,
	fieldLabel,
	onChange,
	...props
}: AppNumberTextFieldProps &
	UseControllerProps<TFieldValues, TName> &
	TextFieldProps) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	return (
		<Box display='flex' flexDirection='column' gap={1} minWidth='160px'>
			<Typography variant='body2' color='common.neutral40'>
				{fieldLabel}
			</Typography>

			<FormControl fullWidth={true} error={!!errorMessage}>
				<TextField
					{...props}
					{...field}
					type='number'
					value={field.value ?? ''}
					onChange={(e) => {
						if (nonNegative) {
							const newValue = e.target.value.replace(/[^0-9]/g, '');
							if (maxLength) {
								const numValue = parseInt(newValue, maxLength);
								if (!isNaN(numValue) && numValue >= 0 && numValue <= 10) {
									field.onChange(newValue);
								} else if (newValue === '') {
									field.onChange('');
								} else {
									field.onChange(newValue.slice(0, newValue.length - 1));
								}
							} else {
								field.onChange(newValue);
							}
						} else {
							field.onChange(e.target.value);
						}

						onChange && onChange();
					}}
					error={!!errorMessage}
					inputProps={{
						maxLength: maxLength,
						max: maxLength,
						min: nonNegative ? 0 : undefined,
						step: 1,
						onKeyDown: (e) => {
							if (nonNegative && (e.key === '-' || e.key === '+')) {
								e.preventDefault();
							}
						},
					}}
				/>
				{maxLength && showMaxLength && (
					<FormHelperText sx={{ textAlign: 'right' }}>
						{field.value?.length ?? 0}/{maxLength}
					</FormHelperText>
				)}
				<FormHelperText>{errorMessage}</FormHelperText>
			</FormControl>
		</Box>
	);
}
