import {
	Box,
	Button,
	Chip,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Typography,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import AppDataGrid, {
	initialPagination,
} from '@/common/components/dataGrid/AppDataGrid';
import { useParams } from 'react-router-dom';
import { filterObjectByKeys } from '@/utils/qsHelper';
import { GridColDef } from '@mui/x-data-grid';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import { UserType } from '@/api/enum/userType.enum';
import {
	LockedFriendListFilter,
	LockedFriendListItem,
} from '@/api/DTO/friend/friend.interface';
import { useGetLockedFriendListQuery } from '@/api/friend/friendApiSlice';
import ClockRelativeSummaryFilterSection from './ClockRelativeSummaryFilterSection';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';

interface Props {
	lockedTotal: number;
}

const ClockRelativeListModal = (props: Props) => {
	const { lockedTotal } = props;
	const { t } = useTranslation();
	const { userId } = useParams();

	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
		setSelectedUser([]);
		setSelectedUserItem([]);
	};

	const { userRoles } = useGetUser<useGetUserResultType>({
		// shouldRefetch: true,
		roleObj: {
			read: ['read', 'user', 'user-list'],
			create: ['create', 'user'],
			send: ['send', 'user'],
		},
	});

	const [selectedUser, setSelectedUser] = useState<string[]>([]);
	const [selectedUserItem, setSelectedUserItem] = useState<
		LockedFriendListItem[]
	>([]);

	const defaultClockUserPageFilter: LockedFriendListFilter = {
		pagination: initialPagination,
		sortings: [],
		searchKey: '',
		userId: userId || '',
	};

	const defaultValues: DefaultValues<LockedFriendListFilter> =
		defaultClockUserPageFilter;

	const [searchFilter, setSearchFilter] = useState<LockedFriendListFilter>(
		defaultClockUserPageFilter
	);

	const methods = useForm<LockedFriendListFilter>({
		defaultValues,
	});

	const transformedSearchParams: LockedFriendListFilter = {
		...defaultClockUserPageFilter,
		...filterObjectByKeys(searchFilter, [
			'pagination',
			'sortings',
			'searchKey',
			'userId',
		]),
	};

	const {
		data: clockUserPageSummary,
		isSuccess: isGetClockUserPageSummarySuccess,
	} = useGetLockedFriendListQuery(transformedSearchParams, {
		// refetchOnMountOrArgChange: true,
		// skip: !userRoles.read,
	});

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const propertyFilterFormResult = value as LockedFriendListFilter;

			setSearchFilter(propertyFilterFormResult);
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const transformResponse = useCallback((response?: LockedFriendListItem[]) => {
		if (response == null) return;
		const newResponse: LockedFriendListItem[] = [];
		response.forEach((item) => {
			newResponse.push({
				...item,
				id: item.id,
			});
		});
		return newResponse;
	}, []);

	const columns: GridColDef[] = [
		{
			field: 'username',
			headerName: t('common.username'),
			flex: 20, // 20% width
			renderCell: (params) => (
				<Box>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='body2'
						variant='regular'
					>
						{params.row.username}
					</Typography>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='caption'
						color='common.neutral50'
						variant='regular'
					>
						{params.row.phone}
					</Typography>
					<Typography
						sx={{
							display: 'block',
						}}
						fontSize='caption'
						color='common.neutral50'
						variant='regular'
					>
						{params.row.email}
					</Typography>
				</Box>
			),
		},
		{
			field: 'district',
			headerName: t('application.district'),
			flex: 9, // 9% width
		},
		{
			field: 'userType',
			headerName: t('common.userCategory'),
			flex: 9, // 9% width
			renderCell: (params) => (
				<Chip
					label={
						params.row.userType === UserType.NORMAL_USER
							? t('common.normalMember')
							: t('common.programMember')
					}
					color={
						params.row.userType === UserType.NORMAL_USER
							? 'chipTertiary'
							: 'chipPrimary'
					}
					variant='outlined'
				/>
			),
		},
		{
			field: 'serviceNumber',
			headerName: t('common.serviceNumber'),
			flex: 11, // 11% width
		},
		{
			field: 'staff',
			headerName: t('common.staffInCharge'),
			flex: 10, // 10% width
		},
	];

	return (
		<>
			<Button
				variant='text'
				sx={{ gap: '5px' }}
				onClick={() => {
					setOpen(true);
				}}
			>
				<DoDisturbOnOutlinedIcon />
				{t('relative.lockedUser')}({lockedTotal})
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='lg'
				fullWidth
			>
				<DialogTitle variant='h5'>
					{t('relative.addRelative') + '：' + t('common.memberUser')}
				</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent
					sx={{
						minHeight: '765px',
					}}
				>
					<FormProvider {...methods}>
						<ClockRelativeSummaryFilterSection
							selectedUserItem={selectedUserItem}
							setSelectedUser={setSelectedUser}
							setSelectedUserItem={setSelectedUserItem}
						/>

						<Box marginTop={2} minWidth='800px'>
							<AppDataGrid
								loading={!isGetClockUserPageSummarySuccess}
								rows={
									transformResponse(clockUserPageSummary?.data?.items) ?? []
								}
								columns={columns}
								rowCount={
									clockUserPageSummary?.data?.pagination.totalItems ?? 0
								}
								getRowId={(row) => row.userId}
								checkboxSelection
								selectionModel={selectedUser}
								onSelectionModelChange={(ids) => {
									const rows =
										transformResponse(clockUserPageSummary?.data?.items) ?? [];
									const selectedIDs = new Set(ids);
									const selectedRowData = rows.filter((row) =>
										selectedIDs.has(row.userId.toString())
									);

									// Update selectedUser
									setSelectedUser(ids.map((id) => String(id)));

									// Update selectedUserItem
									setSelectedUserItem((prevSelected) => {
										const newSelected = new Set(ids.map((id) => String(id)));
										const updatedSelected = prevSelected.filter((item) =>
											newSelected.has(item.userId)
										);

										// Add newly selected items
										selectedRowData.forEach((row) => {
											if (
												!updatedSelected.some(
													(item) => item.userId === row.userId
												)
											) {
												updatedSelected.push(row);
											}
										});

										// Remove unselected items
										const allSelectedIDs = new Set([
											...prevSelected.map((item) => item.userId),
											...ids.map((id) => String(id)),
										]);
										return updatedSelected.filter((item) =>
											allSelectedIDs.has(item.userId)
										);
									});
								}}
							/>
						</Box>
					</FormProvider>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default ClockRelativeListModal;
