import { apiSlice } from '../apiSlice';
import { Paginated, SuccessResponse } from '../DTO/common.interface';
import {
	FriendDetailDto,
	FriendFreeListFilter,
	FriendFreeListItem,
	FriendListFilter,
	FriendListItem,
	FriendOrgListFilter,
	FriendOrgListItem,
	LockedFriendListItem,
	LockedFriendListFilter,
	FriendPermissionDto,
} from '../DTO/friend/friend.interface';

export const reportApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getFriendList: builder.query<
			SuccessResponse<Paginated<FriendListItem>>,
			FriendListFilter
		>({
			query: (body) => ({
				url: `/web/friends/users/page`,
				method: 'POST',
				body,
			}),
			providesTags: ['FriendList'],
		}),
		getLockedFriendList: builder.query<
			SuccessResponse<Paginated<LockedFriendListItem>>,
			LockedFriendListFilter
		>({
			query: (body) => ({
				url: `/web/friends/blocked-users/page`,
				method: 'POST',
				body,
			}),
			providesTags: ['LockedFriendList'],
		}),
		getFriendOrgList: builder.query<
			SuccessResponse<Paginated<FriendOrgListItem>>,
			FriendOrgListFilter
		>({
			query: (body) => ({
				url: `/web/friends/search-org-users/page`,
				method: 'POST',
				body,
			}),
		}),
		getFriendFreeList: builder.query<
			SuccessResponse<Paginated<FriendFreeListItem>>,
			FriendFreeListFilter
		>({
			query: (body) => ({
				url: `/web/friends/search-free-users/page`,
				method: 'POST',
				body,
			}),
		}),
		addFriends: builder.mutation<
			SuccessResponse<any>,
			{ userId: string; targetUserIds: string[] }
		>({
			query: (body) => ({
				url: `/web/friends/users`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['FriendList'],
		}),
		getFriendDetail: builder.query<
			SuccessResponse<FriendDetailDto>,
			{
				friendId: string;
				userId: string;
				friendRequestId: string;
			}
		>({
			query: (body) => ({
				url: body.friendId
					? `/web/friends/users/${body.userId}?friend=${body.friendId}`
					: `/web/friends/users/${body.userId}?friend-request=${body.friendRequestId}`,
				method: 'GET',
			}),
			providesTags: ['FriendDetail'],
		}),
		deleteFriends: builder.mutation<
			SuccessResponse<any>,
			{ friendIds: string[] }
		>({
			query: (body) => ({
				url: `/web/friends/users`,
				method: 'Delete',
				body,
			}),
			invalidatesTags: ['FriendList'],
		}),
		acceptFriends: builder.mutation<
			SuccessResponse<any>,
			{ friendRequestIds: string[] }
		>({
			query: (body) => ({
				url: `/web/friends/users/accept`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['FriendList'],
		}),
		rejectFriends: builder.mutation<
			SuccessResponse<any>,
			{ friendRequestIds: string[] }
		>({
			query: (body) => ({
				url: `/web/friends/users/reject`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['FriendList'],
		}),
		blockFriends: builder.mutation<
			SuccessResponse<any>,
			{ userId: string; targetUserIds: string[] }
		>({
			query: (body) => ({
				url: `/web/friends/users/block`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['FriendList', 'LockedFriendList'],
		}),
		unblockFriends: builder.mutation<
			SuccessResponse<any>,
			{ friendBlockIds: string[] }
		>({
			query: (body) => ({
				url: `/web/friends/users/block`,
				method: 'DELETE',
				body,
			}),
			invalidatesTags: ['FriendList', 'LockedFriendList'],
		}),
		setFriendsPermissions: builder.mutation<
			SuccessResponse<any>,
			{
				userId: string;
				actorId: string;
				stepView?: boolean;
				mapView?: boolean;
				mascotView?: boolean;
				avatarView?: boolean;
			}
		>({
			query: (body) => ({
				url: `/web/friends/permissions`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['FriendDetail'],
		}),
		getFriendsDefaultPermissions: builder.query<SuccessResponse<any>, string>({
			query: (userId) => ({
				url: `/web/friends/users/${userId}/default-permissions`,
				method: 'GET',
			}),
			providesTags: ['FriendsDefaultPermissions'],
		}),
		setFriendsDefaultPermissions: builder.mutation<
			SuccessResponse<any>,
			FriendPermissionDto
		>({
			query: (body) => ({
				url: `/web/friends/users/${body.userId}/default-permissions`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['FriendList', 'FriendsDefaultPermissions'],
		}),
	}),
});

export const {
	useGetFriendListQuery,
	useGetFriendOrgListQuery,
	useGetFriendFreeListQuery,
	useGetFriendDetailQuery,
	useGetLockedFriendListQuery,
	useGetFriendsDefaultPermissionsQuery,
	useAddFriendsMutation,
	useDeleteFriendsMutation,
	useAcceptFriendsMutation,
	useRejectFriendsMutation,
	useBlockFriendsMutation,
	useUnblockFriendsMutation,
	useSetFriendsPermissionsMutation,
	useSetFriendsDefaultPermissionsMutation,
} = reportApiSlice;
