import {
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Grid,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Dispatch, useMemo } from 'react';
import Close from '@mui/icons-material/Close';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { genderOptions } from '@/api/enum/gender.enum';
import ConfirmationDialog from '@/common/components/ConfirmationDialog/ConfirmationDialog';
import useModal from '@/common/components/ConfirmationDialog/useModal';
import { RelativeStatus } from '@/api/enum/relatives.enum';
import EditDetailTop from './EditDetailTop';
import ViewDetailTop from './ViewDetailTop';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { useGetUser, useGetUserResultType } from '@/hooks/useGetUser';
import {
	useAcceptFriendsMutation,
	useBlockFriendsMutation,
	useDeleteFriendsMutation,
	useGetFriendDetailQuery,
	useRejectFriendsMutation,
} from '@/api/friend/friendApiSlice';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT } from '@/utils/dateHelper';
import DoneIcon from '@mui/icons-material/Done';
import { useParams } from 'react-router-dom';

interface DetailDialogProps {
	isOpen: boolean;
	setIsOpen: Dispatch<React.SetStateAction<boolean>>;
	status: RelativeStatus;
	detailObj: any;
}

const DetailDialog = (props: DetailDialogProps) => {
	const { isOpen, setIsOpen, detailObj, status } = props;
	const { t } = useTranslation();
	const showModal = useModal();
	const { userId } = useParams();

	const { userRoles } = useGetUser<useGetUserResultType>({
		shouldRefetch: true,
		roleObj: {
			delete: ['delete', 'relative'],
			block: ['block', 'relative'],
		},
	});

	const [deleteFriends] = useDeleteFriendsMutation();
	const [acceptFriends] = useAcceptFriendsMutation();
	const [rejectFriends] = useRejectFriendsMutation();

	const [blockFriends] = useBlockFriendsMutation();

	const userTypeOptions = [
		{
			value: 1,
			name: t('common.normalMember'),
		},
		{
			value: 2,
			name: t('common.programMember'),
		},
		{
			value: 3,
			name: t('common.communityMember'),
		},
	];

	const { data: resData, isSuccess: isSuccessResData } =
		useGetFriendDetailQuery(
			{
				friendId: detailObj.friendId,
				userId: detailObj.userId,
				friendRequestId: detailObj.friendRequestId,
			},
			{
				refetchOnMountOrArgChange: true,
			}
		);

	const formData = useMemo(() => {
		const obj = {
			...resData?.data,
			...resData?.data.account,
		};
		obj.genderLabel =
			genderOptions.find((option) => option.id == obj.gender)?.name ?? '';
		obj.userTypeLabel = userTypeOptions.find(
			(item) => item.value == obj.userType
		)?.name;
		obj.friendAt = obj.friendAt
			? dayjs(obj.friendAt)
					.locale('zh-hk-my-setting')
					.format(DISPLAY_DATE_FORMAT)
			: '-';

		return obj;
	}, [resData]);

	const handleClose = () => {
		setIsOpen(false);
	};

	const handleBlock = async () => {
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('relative.blockRelative')}
				titleProps={{ variant: 'h6' }}
				description={
					<>
						<Typography variant='body1'>
							{t('relative.blockRelativeMsg')}
						</Typography>
					</>
				}
				cancellationText={t('button.cancel')}
				confirmationText={t('button.block')}
				confirmationButtonProps={{ variant: 'contained' }}
				onConfirm={async () => {
					const response = await blockFriends({
						userId: userId || '',
						targetUserIds: [detailObj.userId],
					});
					if ('data' in response) {
						enqueueSnackbar(`${t('snackbar.blockRelativeSuccessMsg')}`, {
							variant: 'success',
							anchorOrigin: { horizontal: 'center', vertical: 'top' },
							autoHideDuration: 3000,
							action: (key) => {
								return <CloseIcon onClick={() => closeSnackbar(key)} />;
							},
						});
						handleClose();
					}
				}}
			/>
		));
	};

	const handleDelete = async () => {
		console.log(123);
		showModal((props: any) => (
			<ConfirmationDialog
				{...props}
				showCloseIcon={true}
				title={t('relative.deleteRelative')}
				titleProps={{ variant: 'h6' }}
				description={
					<>
						<Typography variant='body1'>
							{t('relative.deleteRelativeMsg')}
						</Typography>
					</>
				}
				cancellationText={t('button.cancel')}
				confirmationText={t('button.delete')}
				confirmationButtonProps={{ color: 'error', variant: 'outlined' }}
				onConfirm={async () => {
					const response = await deleteFriends({
						friendIds: [formData.friendId],
					});
					if ('data' in response) {
						enqueueSnackbar(`${t('snackbar.deleteRelativeSuccessMsg')}`, {
							variant: 'success',
							anchorOrigin: { horizontal: 'center', vertical: 'top' },
							autoHideDuration: 3000,
							action: (key) => {
								return <CloseIcon onClick={() => closeSnackbar(key)} />;
							},
						});
						handleClose();
					}
				}}
			/>
		));
	};

	const handleReject = async () => {
		const response = await rejectFriends({
			friendRequestIds: [formData.friendRequestId],
		});
		if ('data' in response) {
			enqueueSnackbar(`${t('snackbar.rejectFriendSuccess')}`, {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
			handleClose();
		}
	};
	const handlePass = async () => {
		const response = await acceptFriends({
			friendRequestIds: [formData.friendRequestId],
		});
		if ('data' in response) {
			enqueueSnackbar(`${t('snackbar.passFriendSuccess')}`, {
				variant: 'success',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
			handleClose();
		}
	};

	const statusConfig = {
		[RelativeStatus.NORMAL]: {
			dialogTitle: t('relative.userRelativeDetails'),
			detailTopElement: <EditDetailTop formData={formData} />,
			buttonElement: (
				<>
					<DialogActions sx={{ padding: '20px' }}>
						<Button
							color='error'
							onClick={handleDelete}
							startIcon={<DeleteOutlined />}
							disabled={!userRoles.delete}
						>
							{t('button.delete')}
						</Button>
						<Button
							variant='outlined'
							onClick={handleBlock}
							startIcon={<RemoveCircleOutlineIcon />}
							disabled={!userRoles.block}
						>
							{t('button.block')}
						</Button>
					</DialogActions>
				</>
			),
		},
		[RelativeStatus.REQUESTED]: {
			dialogTitle: t('relative.relativeDetails'),
			detailTopElement: <ViewDetailTop formData={formData} />,
			buttonElement: (
				<>
					<DialogActions sx={{ padding: '20px' }}>
						<Button
							color='error'
							onClick={handleReject}
							startIcon={<CloseIcon />}
						>
							{t('button.reject')}
						</Button>
						<Button
							color='success'
							variant='contained'
							onClick={handlePass}
							startIcon={<DoneIcon />}
						>
							{t('button.confirm')}
						</Button>
					</DialogActions>
				</>
			),
		},
	};

	return (
		<>
			{isSuccessResData && (
				<Dialog open={isOpen} onClose={handleClose} maxWidth='md' fullWidth>
					<DialogTitle variant='h5'>
						{statusConfig[status].dialogTitle}
					</DialogTitle>
					<IconButton
						aria-label='close'
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<Close />
					</IconButton>
					<DialogContent>
						<Card>
							<CardContent
								sx={{ padding: '16px', paddingBottom: '16px!important' }}
							>
								<Grid container display='flex' alignItems='center'>
									<Box
										sx={{
											backgroundColor: 'grey.400',
											width: '80px',
											height: '80px',
											borderRadius: '50%',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											position: 'relative',
											marginRight: '40px',
										}}
									>
										<AccountCircleOutlinedIcon
											sx={{
												width: '60px',
												height: '60px',
												color: 'white',
											}}
										/>
										{formData?.userType && (
											<Chip
												label={formData?.userTypeLabel}
												color={
													formData?.userType == 1
														? 'chipFilledTertiary'
														: 'chipPIRUser'
												}
												sx={{
													position: 'absolute',
													top: 0,
													left: 45,
												}}
											/>
										)}
									</Box>
									<Grid xs item>
										{statusConfig[status].detailTopElement}
									</Grid>
								</Grid>
							</CardContent>
						</Card>
						<Grid container marginTop={'16px'}>
							<Grid item xs={6}>
								<Typography variant='subtitle1'>
									{t('common.accountInfo')}
								</Typography>
								<Grid container marginTop={'16px'}>
									<Grid item xs={3}>
										<Typography variant='body2' color='#5D5E61'>
											{t('common.username')}
										</Typography>
									</Grid>
									<Grid item xs={9}>
										<Typography variant='body2'>
											{formData?.username || '-'}
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<Typography variant='body2' color='#5D5E61'>
											{t('common.phoneNumber')}
										</Typography>
									</Grid>
									<Grid item xs={9}>
										<Typography variant='body2'>
											{formData?.phone || '-'}
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<Typography variant='body2' color='#5D5E61'>
											{t('common.email')}
										</Typography>
									</Grid>
									<Grid item xs={9}>
										<Typography variant='body2'>
											{formData?.email || '-'}
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<Typography variant='body2' color='#5D5E61'>
											{t('common.belongedDistrict')}
										</Typography>
									</Grid>
									<Grid item xs={9}>
										<Typography variant='body2'>
											{formData?.fromDistrict || '-'}
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<Typography variant='body2' color='#5D5E61'>
											{t('common.userCategory')}
										</Typography>
									</Grid>
									<Grid item xs={9}>
										<Typography variant='body2'>
											{formData?.userTypeLabel || '-'}
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<Typography variant='body2' color='#5D5E61'>
											{t('common.serviceNumber')}
										</Typography>
									</Grid>
									<Grid item xs={9}>
										<Typography variant='body2'>
											{formData?.serviceNumber || '-'}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle1'>
									{t('common.personalInfo')}
								</Typography>
								<Grid container marginTop={'16px'}>
									<Grid item xs={3}>
										<Typography variant='body2' color='#5D5E61'>
											{t('common.nickname')}
										</Typography>
									</Grid>
									<Grid item xs={9}>
										<Typography variant='body2'>
											{formData?.nickname || '-'}
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<Typography variant='body2' color='#5D5E61'>
											{t('common.gender')}
										</Typography>
									</Grid>
									<Grid item xs={9}>
										<Typography variant='body2'>
											{formData?.genderLabel || '-'}
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<Typography variant='body2' color='#5D5E61'>
											{t('common.dateOfBirth')}
										</Typography>
									</Grid>
									<Grid item xs={9}>
										<Typography variant='body2'>
											{formData?.birthday || '-'}
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<Typography variant='body2' color='#5D5E61'>
											{t('common.personalizedWords')}
										</Typography>
									</Grid>
									<Grid item xs={9}>
										<Typography variant='body2'>
											{formData?.signature || '-'}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</DialogContent>
					{statusConfig[status].buttonElement}
				</Dialog>
			)}
		</>
	);
};

export default DetailDialog;
