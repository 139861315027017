import AppFieldView from '@/common/components/form-view/AppFieldView';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Card,
	CardContent,
	Chip,
	Divider,
	Grid,
	Stack,
	Tooltip,
	Typography,
	styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RichTextDisplay from '@/common/components/form-view/RichTextDisplay';
import AppDataGrid, {
	initialPagination,
} from '@/common/components/dataGrid/AppDataGrid';
import { GridColDef } from '@mui/x-data-grid';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';
import {
	useGetPracticeDetailQuery,
	useGetPracticeMarkPageListQuery,
} from '@/api/practiceRecord/practiceRecordApiSlice';
import dayjs from 'dayjs';
import {
	DISPLAY_CHINESE_DATE_TIME_FORMAT,
	DISPLAY_DATE_FORMAT,
} from '@/utils/dateHelper';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AppElementView from '@/common/components/form-view/AppElementView';
import { AnswerImageType, optionType } from '@/api/enum/exercise.enum';
import SortIcon from '@mui/icons-material/Sort';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import LinearScaleOutlinedIcon from '@mui/icons-material/LinearScaleOutlined';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import { useCallback, useEffect, useState } from 'react';
import { PracticeMarkPageFilter } from '@/api/DTO/practiceRecord/practiceRecord.interface';
import qs from 'qs';
import { filterObjectByKeys, parseSummarySearchParams } from '@/utils/qsHelper';
import { usePracticeRecordOptions } from '@/hooks/usePracticeRecordOptions';
import { showLabel } from '@/utils/helper';
import ContentDialog from './components/ContentDialog';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
	'&.MuiAccordion-root': {
		border: 'none!important',
		boxShadow: 'none!important',
	},
	'&.MuiAccordion-root:before': {
		height: 0,
	},
	'& .MuiAccordionSummary-root': {
		paddingLeft: '0!important',
	},
	'& .MuiAccordionDetails-root': {
		paddingLeft: '0!important',
		paddingTop: '0!important',
	},
	'& .MuiAccordionSummary-content': {
		margin: '20px 0!important',
	},
}));

export default function PracticeRecordDetail() {
	const { t } = useTranslation();
	const { exerciseId } = useParams();
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const { statusList, answerImageOptionList } = usePracticeRecordOptions([
		'statusList',
		'answerImageOptionList',
	]);

	const { data: practiceDetailData } = useGetPracticeDetailQuery(
		exerciseId || '',
		{
			refetchOnMountOrArgChange: true,
			skip: !exerciseId,
		}
	);

	const defaultPageFilter: PracticeMarkPageFilter = {
		pagination: initialPagination,
		sortings: [],
		userExerciseId: exerciseId || '',
	};

	const defaultValues: DefaultValues<PracticeMarkPageFilter> =
		defaultPageFilter;

	const methods = useForm<any>({
		defaultValues,
	});

	// watch serach params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	const transformedSearchParams: PracticeMarkPageFilter = {
		...defaultPageFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'userExerciseId',
		]),
	};

	const { data: pageSummary, isSuccess: isPageSummarySuccess } =
		useGetPracticeMarkPageListQuery(transformedSearchParams, {
			refetchOnMountOrArgChange: true,
		});

	useEffect(() => {
		const subscription = methods.watch((value) => {
			const pageFilterFormResult = value as PracticeMarkPageFilter;

			// validate form and continue if form is valid
			methods.handleSubmit(() => {
				// append to url to reload page
				setSearchParams(qs.stringify(pageFilterFormResult));
			})();
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const transformResponse = useCallback((response?: any[]) => {
		if (response == null) return;
		const newResponse: any[] = [];
		response.forEach((item, index) => {
			newResponse.push({
				...item,
				index: index,
				statusShow: showLabel(item.status, statusList),
				pushedAt: dayjs(item.pushedAt)
					.locale('zh-hk-my-setting')
					.format(DISPLAY_CHINESE_DATE_TIME_FORMAT),
			});
		});
		return newResponse;
	}, []);

	const columns: GridColDef[] = [
		{
			field: 'pushedAt',
			headerName: t('common.updateTime'),
			flex: 1,
			minWidth: 200,
		},
		{
			field: 'userActionShow',
			headerName: t('common.action'),
			flex: 1,
			minWidth: 200,
		},
		{
			field: 'marking',
			headerName: t('common.remark'),
			flex: 1,
			minWidth: 200,
		},
		{
			field: 'status',
			headerName: t('common.status'),
			flex: 1,
			minWidth: 200,
			renderCell: (params) => (
				<Chip
					label={params.row.statusShow}
					color={
						params.row.status == 1
							? 'chipGrey'
							: params.row.status == 3
							? 'chipPrimary'
							: params.row.status == 4
							? 'chipRed'
							: params.row.status == 5
							? 'chipGreen'
							: 'chipGrey'
					}
					variant='outlined'
				/>
			),
		},
	];
	const formatUserResponseSelection = useCallback((data: any) => {
		let userResponseSelection;
		if (!data.picRequired && !data.answerRequired) {
			userResponseSelection = AnswerImageType.ImageOrAnswer;
		} else if (data.picRequired == true && data.answerRequired == true) {
			userResponseSelection = AnswerImageType.ImageAndAnswer;
		} else if (!data.picRequired && data.answerRequired == true) {
			userResponseSelection = AnswerImageType.Answer;
		} else if (data.picRequired == true && !data.answerRequired) {
			userResponseSelection = AnswerImageType.Image;
		}
		return userResponseSelection;
	}, []);

	const getMinMaxValue = useCallback((numbers: any) => {
		const minValue = numbers.reduce(
			(min: number, current: number) => Math.min(min, current),
			Infinity
		);
		const maxValue = numbers.reduce(
			(max: number, current: number) => Math.max(max, current),
			-Infinity
		);

		return `${minValue} ~ ${maxValue}`;
	}, []);
	return (
		<>
			<Card sx={{ marginTop: '24px' }}>
				<CardContent>
					<Typography variant='h6'>{t('common.setup')}</Typography>
					<Grid container spacing={3} marginTop={0} marginBottom={3}>
						<Grid item xs={6}>
							<AppFieldView
								label={t('common.createDate')}
								value={dayjs(practiceDetailData?.data.createdAt)
									.locale('zh-hk-my-setting')
									.format(DISPLAY_CHINESE_DATE_TIME_FORMAT)}
							/>
						</Grid>
						<Grid item xs={6}>
							<AppFieldView
								label={t('practiceRecord.assignedUsers')}
								value={
									`${practiceDetailData?.data.username}` +
									(practiceDetailData?.data.nickname
										? `(${practiceDetailData?.data.nickname})`
										: '')
								}
							/>
						</Grid>
					</Grid>

					<Divider />

					<StyledAccordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel1-content'
							id='panel1-header'
						>
							<Typography variant='h6'>
								{t('practiceRecord.exerciseContent')}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Grid container spacing={3}>
								<Grid item xs={6}>
									<AppFieldView
										label={t('practiceRecord.exerciseCategory')}
										value={practiceDetailData?.data.exerciseCategoryShow || '-'}
									/>
								</Grid>
								{practiceDetailData?.data.exerciseCategory == 1 && (
									<>
										<Grid item xs={6}>
											<AppFieldView
												label={t('practiceRecord.expirationDate')}
												value={dayjs(practiceDetailData?.data.deadline)
													.locale('zh-hk-my-setting')
													.format(DISPLAY_DATE_FORMAT)}
											/>
										</Grid>
										<Grid item xs={12}>
											<AppFieldView
												label={t('practiceRecord.exerciseTitle')}
												value={
													practiceDetailData?.data.practiceDetailDTO.title ||
													'-'
												}
											/>
										</Grid>
										<Grid item xs={12}>
											<AppFieldView
												label={t('practiceRecord.questionTitle')}
												value={
													practiceDetailData?.data.practiceDetailDTO.subTitle ||
													'-'
												}
											/>
										</Grid>
										<Grid item xs={12}>
											<RichTextDisplay
												label={`${t('practiceRecord.promptText')}${t(
													'common.optional'
												)}`}
												editorStateString={
													practiceDetailData?.data.practiceDetailDTO.content
												}
											/>
										</Grid>
										<Grid item xs={12}>
											<AppElementView
												label={t('practiceRecord.questionType')}
												value={
													<Stack
														direction='row'
														sx={{
															justifyContent: 'flex-start',
															alignItems: 'center',
														}}
													>
														{practiceDetailData?.data.practiceDetailDTO.type ==
														optionType.Radio ? (
															<RadioButtonCheckedIcon />
														) : practiceDetailData?.data.practiceDetailDTO
																.type == optionType.Multiple ? (
															<CheckBoxOutlinedIcon />
														) : practiceDetailData?.data.practiceDetailDTO
																.type == optionType.Linear ? (
															<LinearScaleOutlinedIcon />
														) : practiceDetailData?.data.practiceDetailDTO
																.type == optionType.MoodThermometer ? (
															<EmojiEmotionsOutlinedIcon />
														) : practiceDetailData?.data.practiceDetailDTO
																.type == optionType.AnswerImage ? (
															<SortIcon />
														) : (
															''
														)}
														<span style={{ paddingLeft: '5px' }}>
															{
																practiceDetailData?.data.practiceDetailDTO
																	.typeShow
															}
														</span>
													</Stack>
												}
											/>
										</Grid>
										{practiceDetailData?.data.practiceDetailDTO.type ==
											optionType.AnswerImage && (
											<>
												<Grid item xs={12}>
													<AppFieldView
														label={t('practiceRecord.userResponseSelection')}
														value={showLabel(
															formatUserResponseSelection(
																practiceDetailData?.data.practiceDetailDTO
															),
															answerImageOptionList
														)}
													/>
												</Grid>
												{formatUserResponseSelection(
													practiceDetailData?.data.practiceDetailDTO
												) == AnswerImageType.Image && (
													<Grid item xs={12}>
														<AppFieldView
															label={t('practiceRecord.imageTotal')}
															value={
																practiceDetailData?.data.practiceDetailDTO
																	.updatePicTotal
															}
														/>
													</Grid>
												)}
												{formatUserResponseSelection(
													practiceDetailData?.data.practiceDetailDTO
												) == AnswerImageType.Answer && (
													<>
														<Grid item xs={12}>
															<AppFieldView
																label={t('practiceRecord.answerNum')}
																value={
																	practiceDetailData?.data.practiceDetailDTO
																		.optionTotal
																}
															/>
														</Grid>
														{[
															practiceDetailData?.data.practiceDetailDTO
																.answerTitle1,
															practiceDetailData?.data.practiceDetailDTO
																.answerTitle2,
															practiceDetailData?.data.practiceDetailDTO
																.answerTitle3,
															practiceDetailData?.data.practiceDetailDTO
																.answerTitle4,
															practiceDetailData?.data.practiceDetailDTO
																.answerTitle4,
														].map((title, index) => {
															return (
																title && (
																	<Grid item xs={12} key={index}>
																		<Typography
																			variant='caption'
																			marginBottom={'5px'}
																		>
																			{`${t('practiceRecord.which')} ${
																				index + 1
																			} ${t('practiceRecord.question')}`}
																		</Typography>
																		<AppFieldView value={title} />
																	</Grid>
																)
															);
														})}
													</>
												)}
												{formatUserResponseSelection(
													practiceDetailData?.data.practiceDetailDTO
												) == AnswerImageType.ImageAndAnswer && (
													<>
														<Grid item xs={6}>
															<AppFieldView
																label={t('practiceRecord.imageTotal')}
																value={
																	practiceDetailData?.data.practiceDetailDTO
																		.updatePicTotal
																}
															/>
														</Grid>
														<Grid item xs={6}>
															<AppFieldView
																label={t('practiceRecord.answerNum')}
																value={
																	practiceDetailData?.data.practiceDetailDTO
																		.optionTotal
																}
															/>
														</Grid>
														{[
															practiceDetailData?.data.practiceDetailDTO
																.answerTitle1,
															practiceDetailData?.data.practiceDetailDTO
																.answerTitle2,
															practiceDetailData?.data.practiceDetailDTO
																.answerTitle3,
															practiceDetailData?.data.practiceDetailDTO
																.answerTitle4,
															practiceDetailData?.data.practiceDetailDTO
																.answerTitle4,
														].map((title, index) => {
															return (
																title && (
																	<Grid item xs={12} key={index}>
																		<Typography
																			variant='caption'
																			marginBottom={'5px'}
																		>
																			{`${t('practiceRecord.which')} ${
																				index + 1
																			} ${t('practiceRecord.question')}`}
																		</Typography>
																		<AppFieldView value={title} />
																	</Grid>
																)
															);
														})}
													</>
												)}
												{formatUserResponseSelection(
													practiceDetailData?.data.practiceDetailDTO
												) == AnswerImageType.ImageOrAnswer && (
													<>
														<Grid item xs={6}>
															<AppFieldView
																label={t('practiceRecord.imageTotal')}
																value={
																	practiceDetailData?.data.practiceDetailDTO
																		.updatePicTotal
																}
															/>
														</Grid>
														<Grid item xs={6}>
															<AppFieldView
																label={t('practiceRecord.answerNum')}
																value={
																	practiceDetailData?.data.practiceDetailDTO
																		.optionTotal
																}
															/>
														</Grid>
														{[
															practiceDetailData?.data.practiceDetailDTO
																.answerTitle1,
															practiceDetailData?.data.practiceDetailDTO
																.answerTitle2,
															practiceDetailData?.data.practiceDetailDTO
																.answerTitle3,
															practiceDetailData?.data.practiceDetailDTO
																.answerTitle4,
															practiceDetailData?.data.practiceDetailDTO
																.answerTitle4,
														].map((title, index) => {
															return (
																title && (
																	<Grid item xs={12} key={index}>
																		<Typography
																			variant='caption'
																			marginBottom={'5px'}
																		>
																			{`${t('practiceRecord.which')} ${
																				index + 1
																			} ${t('practiceRecord.question')}`}
																		</Typography>
																		<AppFieldView value={title} />
																	</Grid>
																)
															);
														})}
													</>
												)}
											</>
										)}
										{practiceDetailData?.data.practiceDetailDTO.type ==
											optionType.Radio && (
											<>
												{[
													practiceDetailData?.data.practiceDetailDTO.option1,
													practiceDetailData?.data.practiceDetailDTO.option2,
													practiceDetailData?.data.practiceDetailDTO.option3,
													practiceDetailData?.data.practiceDetailDTO.option4,
													practiceDetailData?.data.practiceDetailDTO.option5,
													practiceDetailData?.data.practiceDetailDTO.option6,
													practiceDetailData?.data.practiceDetailDTO.option7,
													practiceDetailData?.data.practiceDetailDTO.option8,
													practiceDetailData?.data.practiceDetailDTO.option9,
													practiceDetailData?.data.practiceDetailDTO.option10,
													practiceDetailData?.data.practiceDetailDTO.option11,
												].map((option, index) => {
													return (
														option && (
															<Grid item xs={12} key={index}>
																<Typography
																	variant='caption'
																	marginBottom={'5px'}
																>
																	{t('common.option')}
																	{index + 1}
																</Typography>
																<AppFieldView value={option} />
															</Grid>
														)
													);
												})}
											</>
										)}
										{practiceDetailData?.data.practiceDetailDTO.type ==
											optionType.Multiple && (
											<>
												{[
													practiceDetailData?.data.practiceDetailDTO.option1,
													practiceDetailData?.data.practiceDetailDTO.option2,
													practiceDetailData?.data.practiceDetailDTO.option3,
													practiceDetailData?.data.practiceDetailDTO.option4,
													practiceDetailData?.data.practiceDetailDTO.option5,
													practiceDetailData?.data.practiceDetailDTO.option6,
													practiceDetailData?.data.practiceDetailDTO.option7,
													practiceDetailData?.data.practiceDetailDTO.option8,
													practiceDetailData?.data.practiceDetailDTO.option9,
													practiceDetailData?.data.practiceDetailDTO.option10,
													practiceDetailData?.data.practiceDetailDTO.option11,
												].map((option, index) => {
													return (
														option && (
															<Grid item xs={12} key={index}>
																<Typography
																	variant='caption'
																	marginBottom={'5px'}
																>
																	{t('common.option')}
																	{index + 1}
																</Typography>
																<AppFieldView value={option} />
															</Grid>
														)
													);
												})}
											</>
										)}
										{practiceDetailData?.data.practiceDetailDTO.type ==
											optionType.Linear && (
											<>
												<Grid item xs={12}>
													<AppFieldView
														label={t('practiceRecord.linearDegrees')}
														value={getMinMaxValue([
															practiceDetailData?.data.practiceDetailDTO
																.option1,
															practiceDetailData?.data.practiceDetailDTO
																.option2,
															practiceDetailData?.data.practiceDetailDTO
																.option3,
															practiceDetailData?.data.practiceDetailDTO
																.option4,
															practiceDetailData?.data.practiceDetailDTO
																.option5,
															practiceDetailData?.data.practiceDetailDTO
																.option6,
															practiceDetailData?.data.practiceDetailDTO
																.option7,
															practiceDetailData?.data.practiceDetailDTO
																.option8,
															practiceDetailData?.data.practiceDetailDTO
																.option9,
															practiceDetailData?.data.practiceDetailDTO
																.option10,
															practiceDetailData?.data.practiceDetailDTO
																.option11,
														])}
													/>
												</Grid>
												<Grid item xs={6}>
													<AppFieldView
														label={t('practiceRecord.tagMin')}
														value={
															practiceDetailData?.data.practiceDetailDTO
																.minLabel
														}
													/>
												</Grid>
												<Grid item xs={6}>
													<AppFieldView
														label={t('practiceRecord.tagMax')}
														value={
															practiceDetailData?.data.practiceDetailDTO
																.maxLabel
														}
													/>
												</Grid>
											</>
										)}
									</>
								)}
								{practiceDetailData?.data.exerciseCategory == 2 && (
									<>
										<Grid item xs={6}>
											<AppFieldView
												label={t('practiceRecord.correctionMethod')}
												labelElement={
													<Tooltip
														title={
															<>
																{t('practiceRecord.autoTips')}
																<br />
																{t('practiceRecord.manualTips')}
															</>
														}
														arrow
														placement='top'
													>
														<InfoOutlinedIcon
															sx={{
																fontSize: '16px',
																position: 'relative',
																top: '3px',
																left: '3px',
															}}
														/>
													</Tooltip>
												}
												value={
													practiceDetailData?.data.questionnaireDTO
														?.markingMethodShow || '-'
												}
											/>
										</Grid>
										<Grid item xs={12}>
											<AppFieldView
												label={t('practiceRecord.expirationDate')}
												value={dayjs(practiceDetailData?.data.deadline)
													.locale('zh-hk-my-setting')
													.format(DISPLAY_CHINESE_DATE_TIME_FORMAT)}
											/>
										</Grid>
										<Grid item xs={12}>
											<AppFieldView
												label={t('practiceRecord.exerciseTitle')}
												value={
													practiceDetailData?.data.questionnaireDTO.title || '-'
												}
											/>
										</Grid>
										<Grid item xs={12}>
											<AppFieldView
												label={t('practiceRecord.allocationCourse')}
												value={
													practiceDetailData?.data.questionnaireDTO
														.lessonName || '-'
												}
											/>
										</Grid>
										<Grid item xs={12}>
											<AppFieldView
												label={t('practiceRecord.selectChapter')}
												value={
													practiceDetailData?.data.questionnaireDTO
														.chapterTitle || '-'
												}
											/>
										</Grid>
									</>
								)}
							</Grid>
						</AccordionDetails>
					</StyledAccordion>

					<Divider sx={{ marginBottom: '32px' }} />

					<Typography variant='h6'>
						{t('practiceRecord.completionReward')}
					</Typography>
					<Grid container spacing={3} marginTop={0} marginBottom={3}>
						<Grid item xs={6}>
							<AppFieldView
								label={t('practiceRecord.goldNum')}
								value={String(practiceDetailData?.data.coin) || '-'}
							/>
						</Grid>
						<Grid item xs={6}>
							<AppFieldView
								label={t('practiceRecord.addEmpirical')}
								value={String(practiceDetailData?.data.experience) || '-'}
							/>
						</Grid>
					</Grid>
				</CardContent>
			</Card>

			<Card sx={{ marginTop: '24px' }}>
				<CardContent>
					<Stack
						direction={'row'}
						justifyContent={'space-between'}
						marginBottom={'32px'}
					>
						<Typography variant='h6'>
							{t('practiceRecord.correctionLog')}
						</Typography>
						{practiceDetailData?.data.practiceDetailDTO &&
							practiceDetailData?.data.practiceDetailDTO.status >= 3 && (
								<ContentDialog practiceDetailData={practiceDetailData?.data} />
							)}
						{practiceDetailData?.data.questionnaireDTO &&
							practiceDetailData?.data.questionnaireDTO.status >= 3 && (
								<ContentDialog practiceDetailData={practiceDetailData?.data} />
							)}
					</Stack>
					<FormProvider {...methods}>
						<form>
							<AppDataGrid
								loading={!isPageSummarySuccess}
								rows={transformResponse(pageSummary?.data?.items) ?? []}
								columns={columns}
								rowCount={pageSummary?.data?.pagination.totalItems ?? 0}
								getRowId={(row) => row.index}
							/>
						</form>
					</FormProvider>
				</CardContent>
			</Card>
		</>
	);
}
