import { relativeStatusOptions } from '@/api/enum/relatives.enum';
import AppMultipleDropDownMenu from '@/common/components/form-inputs/AppMultipleDropDownMenu';
import { Button, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetLockedFriendListQuery } from '@/api/friend/friendApiSlice';
import { useParams } from 'react-router-dom';
import ClockRelativeListModal from './ClockRelativeListModal';
import FriendPowerSettingModal from './FriendPowerSettingModal';

export default function RelativeListSummaryFilterSection() {
	const { t } = useTranslation();
	const { control, watch, resetField } = useFormContext<any>();
	const { userId } = useParams();

	const { data: lockedFriendData } = useGetLockedFriendListQuery(
		{
			pagination: { page: 1, size: 100000 },
			sortings: [],
			userId: userId || '',
			searchKey: '',
		},
		{ refetchOnMountOrArgChange: true }
	);

	return (
		<Grid container rowSpacing={2} columnSpacing={1} sx={{ width: '100%' }}>
			<Grid item xs={4}>
				<AppMultipleDropDownMenu
					control={control}
					name='friendStatuses'
					size='small'
					label={t('common.status')}
					options={relativeStatusOptions}
					emptyArrayIfNoSelection
				/>
			</Grid>
			<Grid item xs={4}>
				<ClockRelativeListModal
					lockedTotal={lockedFriendData?.data.pagination.totalItems || 0}
				/>
				<FriendPowerSettingModal />
			</Grid>
		</Grid>
	);
}
