import { Option } from '../DTO/common.interface';

export enum RelativeStatus {
	REQUESTED = 0,
	NORMAL = 1,
	// BLOCKED = 2,
}

export const relativeStatusOptions: Option[] = [
	{
		id: RelativeStatus.NORMAL,
		name: '正常',
	},
	{
		id: RelativeStatus.REQUESTED,
		name: '請求中',
	},
	// {
	// 	id: RelativeStatus.BLOCKED,
	// 	name: '被封鎖',
	// },
];

export enum PermissionEnum {
	ME = 0,
	FRIEND = 1,
	ALL = 2,
}
export const PermissionOptions: Option[] = [
	{
		id: PermissionEnum.ME,
		name: '只有我',
	},
	{
		id: PermissionEnum.FRIEND,
		name: '僅親友可見',
	},
	{
		id: PermissionEnum.ALL,
		name: '所有人可見',
	},
];
