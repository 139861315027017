import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useController,
	useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SetAnswer } from '@/api/DTO/studyCourse/studyCourseList.interface';
import Close from '@mui/icons-material/Close';
import { SubjectsOptionsItem } from '@/api/DTO/studyCourse/studyCourseList.interface';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { SubjectType } from '@/api/enum/studyCourse.enum';
import AppRadioGroup from '@/common/components/form-inputs/AppRadioGroup';
import AppTextField from '@/common/components/form-inputs/AppTextField';
import AppMultipleDropDownMenu from '@/common/components/form-inputs/AppMultipleDropDownMenu';
import AppDropDownMenu from '@/common/components/form-inputs/AppDropDownMenu';
import SingleCheckbox from '@/common/components/form-inputs/SingleCheckbox';

interface Props {
	answerList: SetAnswer;
	subjectsItem: SubjectsOptionsItem;
	formMethods: any;
	subjectsUpdate: any;
	subjectsItemIndex: number;
	subjectsItemOptions: SubjectsOptionsItem[];
	optionType: number | string;
}

export default function SettingAnswerModal(props: Props) {
	const {
		subjectsItem,
		formMethods,
		subjectsItemIndex,
		subjectsItemOptions,
		optionType,
		answerList,
		subjectsUpdate,
	} = props;
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	const subjectsItemTitle = formMethods.watch(
		`subjects[${subjectsItemIndex}].title`
	);

	const defaultValues: DefaultValues<SetAnswer> = {
		radioAnswer: '',
		linearNumMaxAnswer: null,
		multipleAnswer: {},
		gridAnswer: {},
		gridAnswerNum: '',
		multipleAnswerNum: '',
	};
	const getFormSchema = (optionType: number | string) => {
		const radioAnswerSchema =
			(SubjectType.Radio == optionType &&
				Yup.string().required(t('errorMessage.pleaseSelect'))) ||
			Yup.string();
		const multipleAnswerSchema =
			(SubjectType.Multiple == optionType &&
				Yup.object().test(
					'multipleAnswer',
					t('errorMessage.pleaseSelect'),
					(value: any) => {
						return Object.values(value).some(Boolean);
					}
				)) ||
			Yup.object();
		const gridAnswerSchema =
			(SubjectType.Grid == optionType &&
				Yup.object().test(
					'gridAnswer',
					t('errorMessage.pleaseSelect'),
					(value: any) => {
						return Object.values(value).some(Boolean);
					}
				)) ||
			Yup.object();
		const linearNumMaxAnswerSchema =
			(SubjectType.Linear == optionType &&
				Yup.number().required(t('errorMessage.required'))) ||
			Yup.string().nullable();
		const gridAnswerNumSchema =
			(SubjectType.Grid == optionType &&
				Yup.mixed().test({
					name: 'gridAnswerNum',
					message: '',
					test: (value, context) => {
						const { gridAnswerNum, gridAnswer } = context.parent;
						const count = Object.keys(gridAnswer).reduce((count, key) => {
							return gridAnswer[key] === true ? count + 1 : count;
						}, 0);
						return gridAnswerNum == count;
					},
				})) ||
			Yup.string().nullable();
		const multipleAnswerNumSchema =
			(SubjectType.Multiple == optionType &&
				Yup.mixed().test({
					name: 'multipleAnswerNum',
					message: '',
					test: (value, context) => {
						console.log(123);
						const { multipleAnswerNum, multipleAnswer } = context.parent;
						const count = Object.keys(multipleAnswer).reduce((count, key) => {
							return multipleAnswer[key] === true ? count + 1 : count;
						}, 0);
						return multipleAnswerNum == count;
					},
				})) ||
			Yup.string().nullable();

		return Yup.object().shape({
			radioAnswer: radioAnswerSchema,
			multipleAnswer: multipleAnswerSchema,
			gridAnswer: gridAnswerSchema,
			linearNumMaxAnswer: linearNumMaxAnswerSchema,
			gridAnswerNum: gridAnswerNumSchema,
			multipleAnswerNum: multipleAnswerNumSchema,
			falseAnswer: Yup.string()
				.required(t('errorMessage.required'))
				.max(200, t('errorMessage.max200', { max: 200 })),
			trueAnswer: Yup.string()
				.required(t('errorMessage.required'))
				.max(200, t('errorMessage.max200', { max: 200 })),
		});
	};

	const methods = useForm<SetAnswer>({
		defaultValues,
		resolver: yupResolver(getFormSchema(optionType)),
	});

	const multipleAnswerField = useController({
		name: 'multipleAnswer',
		control: methods.control,
	});
	const gridAnswerField = useController({
		name: 'gridAnswer',
		control: methods.control,
	});
	const gridAnswerNumField = useController({
		name: 'gridAnswerNum',
		control: methods.control,
	});
	const multipleAnswerNumField = useController({
		name: 'multipleAnswerNum',
		control: methods.control,
	});

	useEffect(() => {
		if (open && answerList) {
			console.log(answerList);
			methods.setValue('trueAnswer', answerList.trueAnswer || '');
			methods.setValue('falseAnswer', answerList.falseAnswer || '');
			methods.setValue('multipleAnswer', answerList.multipleAnswer || {});
			methods.setValue('gridAnswer', answerList.gridAnswer || {});
			methods.setValue('radioAnswer', answerList.radioAnswer || '');
			methods.setValue(
				'linearNumMaxAnswer',
				answerList.linearNumMaxAnswer ?? null
			);
			methods.setValue('gridAnswerNum', answerList.gridAnswerNum ?? null);
			methods.setValue(
				'multipleAnswerNum',
				answerList.multipleAnswerNum ?? null
			);
		} else {
			methods.setValue('trueAnswer', '');
			methods.setValue('falseAnswer', '');
		}
	}, [answerList, open]);

	const newSubjectsItemOptions = useMemo(() => {
		const list = subjectsItemOptions.filter(
			(item: SubjectsOptionsItem) => item.pcode == subjectsItem.code
		);
		return list;
	}, [subjectsItemOptions]);

	const canOpenDialog = () => {
		if (
			optionType == SubjectType.Radio ||
			optionType == SubjectType.Multiple ||
			optionType == SubjectType.Grid
		) {
			const isOptionEmpty = subjectsItemOptions.find(
				(item: SubjectsOptionsItem) => {
					if (item.pcode == subjectsItem.code) {
						if (!item.text) return true;
					}
				}
			);
			if (!subjectsItemTitle || !!isOptionEmpty) {
				return t('snackbar.pleaseCompleteSubjectOption');
			}
		}

		if (optionType == SubjectType.Linear) {
			let isMinMax = false;
			const isLinearNumMaxEmpty = subjectsItemOptions.find(
				(item: SubjectsOptionsItem) => {
					if (item.pcode == subjectsItem.code) {
						if (!item.linearNumMax) return true;
						if (
							item.linearNumMin &&
							item.linearNumMax &&
							item.linearNumMax < item.linearNumMin
						)
							isMinMax = true;
					}
				}
			);
			if (!subjectsItemTitle || !!isLinearNumMaxEmpty)
				return t('snackbar.pleaseCompleteSubjectLinearNumMax');
			if (isMinMax) return t('snackbar.isMinMax');
		}
		return null;
	};

	const handleOpen = () => {
		const message = canOpenDialog();
		if (message) {
			enqueueSnackbar(message, {
				variant: 'warning',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
				autoHideDuration: 3000,
				action: (key) => {
					return <CloseIcon onClick={() => closeSnackbar(key)} />;
				},
			});
		} else {
			setOpen(true);
		}
	};

	const handleClose = () => {
		methods.reset();
		setOpen(false);
	};

	const onSubmit: SubmitHandler<SetAnswer> = async (data) => {
		console.log(data);
		const obj = formMethods
			.getValues('answerList')
			.find((item: { pcode: string }) => {
				return item.pcode == subjectsItem.code;
			});
		let newAnswerList = [...formMethods.getValues('answerList')];
		if (obj) {
			newAnswerList = [...formMethods.getValues('answerList')].map((item) => {
				if (item.pcode == subjectsItem.code) {
					return { ...data, pcode: subjectsItem.code };
				}
				return item;
			});
		} else {
			newAnswerList.push({ ...data, pcode: subjectsItem.code });
		}

		formMethods.setValue('answerList', newAnswerList);

		const formSubjects = formMethods.getValues('subjects')[subjectsItemIndex];

		subjectsUpdate(subjectsItemIndex, { ...formSubjects, isSetAnswer: true });

		enqueueSnackbar(t('snackbar.setAnswerSuccess'), {
			variant: 'success',
			anchorOrigin: { horizontal: 'center', vertical: 'top' },
			autoHideDuration: 3000,
			action: (key) => {
				return <CloseIcon onClick={() => closeSnackbar(key)} />;
			},
		});

		handleClose();
	};

	const generateNumberObjects = (min: number, max: number) => {
		const result = [];
		for (let i = min; i <= max; i++) {
			result.push({
				id: i - min + 1,
				name: i.toString(),
			});
		}
		return result;
	};

	// const aaa: any = async () => {
	// 	await methods.trigger();
	// 	console.log(methods.formState.errors);
	// };

	return (
		<>
			<Button
				sx={{ marginLeft: '16px' }}
				variant='outlined'
				startIcon={<PlaylistAddCheckIcon />}
				onClick={() => handleOpen()}
			>
				{t('button.setAnswer')}
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth='lg'
				fullWidth
			>
				<DialogTitle variant='h6'>{t('studyCourse.setAnswer')}</DialogTitle>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
				<DialogContent>
					<Typography variant='body1'>
						{t('studyCourse.chooseAnswer')}
					</Typography>
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<Grid container spacing={2} marginTop={0}>
								{optionType == SubjectType.Radio && (
									<>
										<Grid item xs={12}>
											<Typography variant='body1'>
												{subjectsItemTitle}
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<AppRadioGroup
												itemStyle={{ width: '100%' }}
												control={methods.control}
												name='radioAnswer'
												row
												options={newSubjectsItemOptions.map((item) => {
													return {
														id: item.code,
														name: item.text,
													};
												})}
											/>
										</Grid>
									</>
								)}

								{optionType == SubjectType.Multiple && (
									<>
										{multipleAnswerNumField.fieldState.error?.type ==
											'multipleAnswerNum' && (
											<Grid item xs={12}>
												<Typography variant='body1' color={'#BA1A1A'}>
													{t('errorMessage.diffTrueAnswerCount')}
												</Typography>
											</Grid>
										)}

										<Grid item xs={12}>
											<AppDropDownMenu
												control={methods.control}
												name='multipleAnswerNum'
												label={t('studyCourse.answerNumber')}
												options={Array(
													Number(newSubjectsItemOptions.length || 0)
												)
													.fill(null)
													.map((_, index) => ({
														id: index + 1,
														name: (index + 1).toString(),
													}))}
												required
											/>
										</Grid>

										<Grid item xs={12}>
											<Typography variant='body1'>
												{subjectsItemTitle}
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<FormControl
												error={!!multipleAnswerField.fieldState.error?.message}
												fullWidth
											>
												{newSubjectsItemOptions.map((item, index) => (
													<Stack
														direction={'row'}
														alignItems={'center'}
														sx={{ width: '100%' }}
														key={item.code}
													>
														<SingleCheckbox
															control={methods.control}
															name={`multipleAnswer[${item.code}]`}
															label={item.text || ''}
														/>
													</Stack>
												))}
												<FormHelperText>
													{multipleAnswerField.fieldState.error?.message}
												</FormHelperText>
											</FormControl>
										</Grid>
									</>
								)}

								{optionType == SubjectType.Linear && (
									<>
										<Grid item xs={12}>
											<Typography variant='body1'>
												{subjectsItemTitle}
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<AppDropDownMenu
												control={methods.control}
												name='linearNumMaxAnswer'
												label={t('studyCourse.answerValue')}
												options={generateNumberObjects(
													Number(newSubjectsItemOptions[0]?.linearNumMin || 0),
													Number(newSubjectsItemOptions[0]?.linearNumMax || 0)
												)}
												required
											/>
										</Grid>
									</>
								)}

								{optionType == SubjectType.Grid && (
									<>
										{gridAnswerNumField.fieldState.error?.type ==
											'gridAnswerNum' && (
											<Grid item xs={12}>
												<Typography variant='body1' color={'#BA1A1A'}>
													{t('errorMessage.diffTrueAnswerCount')}
												</Typography>
											</Grid>
										)}

										<Grid item xs={12}>
											<AppDropDownMenu
												control={methods.control}
												name='gridAnswerNum'
												label={t('studyCourse.answerNumber')}
												options={Array(
													Number(newSubjectsItemOptions.length || 0)
												)
													.fill(null)
													.map((_, index) => ({
														id: index + 1,
														name: (index + 1).toString(),
													}))}
												required
											/>
										</Grid>
										<Grid item xs={12}>
											<Typography variant='body1'>
												{subjectsItemTitle}
											</Typography>
										</Grid>
										<FormControl
											error={!!gridAnswerField.fieldState.error?.message}
											fullWidth
										>
											<Grid container paddingLeft={'15px'}>
												{newSubjectsItemOptions.map((item) => (
													<Grid item xs={6} key={item.code}>
														<Stack direction={'row'} alignItems={'center'}>
															<SingleCheckbox
																control={methods.control}
																name={`gridAnswer[${item.code}]`}
																label={item.text || ''}
															/>
														</Stack>
													</Grid>
												))}
											</Grid>
											<FormHelperText>
												{gridAnswerField.fieldState.error?.message}
											</FormHelperText>
										</FormControl>
									</>
								)}
							</Grid>

							<Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />

							<Grid container spacing={2} marginTop={0}>
								<Grid item xs={12} marginBottom={'20px'}>
									<Typography variant='body1'>
										{t('studyCourse.answerFeedback')}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<AppTextField
										control={methods.control}
										name='trueAnswer'
										label={t('studyCourse.rightAnswer')}
										maxLength={200}
										showMaxLength
									/>
								</Grid>
								<Grid item xs={12}>
									<AppTextField
										control={methods.control}
										name='falseAnswer'
										label={t('studyCourse.wrongAnswer')}
										maxLength={200}
										showMaxLength
									/>
								</Grid>
							</Grid>
						</form>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Button
						type='submit'
						variant='contained'
						onClick={methods.handleSubmit(onSubmit)}
					>
						{t('button.save')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
